import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { receiveToken, resetPassword } from "../redux/reducers/auth";
import { Redirect } from "react-router-dom";

const ForgotPassword = ({
  recieveAccessToken,
  match,
  id,
  validToken,
  validatingToken,
  resetForgotPassword,
  resetPasswordSuccessful,
}) => {
  const { accessToken } = match.params;
  useEffect(() => {
    recieveAccessToken(accessToken);
  }, []);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
  };
  if (validatingToken) {
    return <p>Validating</p>;
  } else if (!validToken) {
    return <p>Invalid</p>;
  }
  if (resetPasswordSuccessful) {
    return <Redirect to={"/authenticate/login"} />;
  }
  return (
    <Form>
      <div className="mb-4">
        <Form.Label htmlFor="email">Password</Form.Label>
        <Form.Control
          name="password"
          type="password"
          autoComplete="off"
          required
          value={formData.password}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <Form.Label htmlFor="email">Confirm password</Form.Label>
        <Form.Control
          name="confirmPassword"
          type="password"
          autoComplete="off"
          required
          value={formData.confirmPassword}
          onChange={handleChange}
        />
      </div>
      <Button
        disabled={
          !formData.password ||
          !formData.confirmPassword ||
          formData.password !== formData.confirmPassword
        }
        onClick={() =>
          resetForgotPassword({ password: formData.password, userId: id })
        }
      >
        Send
      </Button>
    </Form>
  );
};

const mapState = (state) => {
  const { id, validToken, validatingToken, resetPasswordSuccessful } =
    state.auth;
  return {
    id,
    validToken,
    validatingToken,
    resetPasswordSuccessful,
  };
};

const mapDispatch = (dispatch) => {
  return {
    resetForgotPassword(data) {
      dispatch(resetPassword(data));
    },
    recieveAccessToken(accessToken) {
      dispatch(receiveToken(accessToken));
    },
  };
};

export default connect(mapState, mapDispatch)(ForgotPassword);
