import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import { updateCompanyPassword } from "../../redux/reducers/company";

const EditCompanyPasswordModal = ({
  companyId,
  companyPassword,
  changeCompanyPassword,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [password, setPassword] = useState(companyPassword);

  const handleUpdate = () => {
    changeCompanyPassword({ companyId, password });
    setModalShow(false);
  };
  return (
    <>
      <Button variant="link" onClick={() => setModalShow(true)}>
        Edit
      </Button>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Company Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                value={password}
                onChange={({ target: { value } }) => setPassword(value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate}>Update</Button>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapState = (state) => {
  return {};
};
const mapDispatch = (dispatch) => {
  return {
    changeCompanyPassword(data) {
      dispatch(updateCompanyPassword(data));
    },
  };
};

export default connect(mapState, mapDispatch)(EditCompanyPasswordModal);
