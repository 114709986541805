import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import { createCompany, createUserCompany } from "../../redux/reducers/company";
import { fetchCategories } from "../../redux/reducers/category";
import CompanyForm from "../formComponents/CompanyForm";

const CreateUserCompanyModal = ({
  addCompany,
  companies,
  getCategories,
  categories,
  surpassedLimit,
}) => {
  useEffect(() => {
    getCategories();
  }, [companies]);
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    password: "",
    category: "",
  });
  const handleChange = ({ target }) => {
    const { value, id } = target;
    setFormData({ ...formData, [id]: value });
  };
  const handleCreate = () => {
    addCompany(formData);
    setModalShow(false);
  };
  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Create
      </Button>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create Company
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {surpassedLimit ? (
            <p>You have surpassed your limit</p>
          ) : (
            <CompanyForm {...{ handleChange, categories }} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCreate}>Create</Button>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapState = (state) => {
  const { companies } = state.company;
  const { categories } = state.category;
  const { auth } = state.auth;
  return {
    companies,
    categories,
  };
};
const mapDispatch = (dispatch) => {
  return {
    addCompany(formData) {
      dispatch(createUserCompany(formData));
    },
    getCategories() {
      dispatch(fetchCategories());
    },
  };
};

export default connect(mapState, mapDispatch)(CreateUserCompanyModal);
