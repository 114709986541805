import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Form, NavLink, Button } from "react-bootstrap";
import { Redirect } from "react-router";
import { executeScraper } from "../redux/reducers/scraper";
import { createQuestion } from "../redux/reducers/question";

const QuestionForm = ({
  company,
  passwordCorrect,
  runScraper,
  interests,
  submitQuestion,
}) => {
  // const [formData, setFormData] = useState({ company: company.name });
  // const handleChange = ({ target }) => {
  //   const { value, id } = target;
  //   setFormData({ ...formData, [id]: value });
  // };
  // const handleCreate = () => {
  //   addCompany(formData);
  //   setModalShow(false);
  // };
  const [ran, setRan] = useState(false);
  const handleClick = () => {
    runScraper();
    setRan(true);
  };
  const handleSubmit = () => {
    const questionData = {
      email: "123test@gmail.com",
      gender: "Male",
      age: 26,
      companyId: company.id,
    };

    const interests = [{ name: "House" }, { name: "dfdsafs" }];

    submitQuestion({ questionData, interests });
  };
  if (!passwordCorrect) {
    return <Redirect to="/" />;
  }
  if (ran && !interests.length) {
    return <h1>Grabbing Interests...</h1>;
  }
  return (
    <Container>
      <h1>{company.name}</h1>
      {!interests.length ? (
        <Button onClick={handleClick}>Facebook Login</Button>
      ) : (
        <Form>
          <Form.Group className="mb-3">
            <Form.Select multiple>
              <Form.Label>What did you purchase from the company?</Form.Label>
              <option>Select Item</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Are you male or female?</Form.Label>
            <Form.Check
              inline
              label="Male"
              name="group1"
              type="radio"
              id={`inline-radio-1`}
            />
            <Form.Check
              inline
              label="Female"
              name="group1"
              type="radio"
              id={`inline-radio-2`}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>What is your current age?</Form.Label>
            <Form.Control name="age" type="number" min="18" max="100" />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check inline type="checkbox" />
            <Form.Label>
              <Container className="d-flex align-items-center">
                By clicking below to submit, you're agreeing to our{" "}
                <NavLink href="#">terms of service</NavLink>
              </Container>
            </Form.Label>
          </Form.Group>
          <Button onClick={handleSubmit}>Submit</Button>
        </Form>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { passwordCorrect, company } = state.company;
  const { interests } = state.scraper;
  return {
    company,
    passwordCorrect,
    interests,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    runScraper: () => {
      dispatch(executeScraper());
    },
    submitQuestion: (body) => {
      dispatch(createQuestion(body));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionForm);
