import { combineReducers } from "redux";

// import auth from "./reducers/auth";

import auth from "./reducers/auth";
import user from "./reducers/user";
import question from "./reducers/question";
import streak from "./reducers/streak";
import company from "./reducers/company";
import scraper from "./reducers/scraper";
import category from "./reducers/category";

const rootReducer = combineReducers({
  auth,
  user,
  question,
  streak,
  company,
  scraper,
  category,
});

export default rootReducer;
