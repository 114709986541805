/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import history from "../../utils/history";

export const fetchUsers = createAsyncThunk("user/fetchUsers", async () => {
  const { data } = await axios.get(`/api/admin/users`);
  return data;
});

export const fetchUserBySearchTerm = createAsyncThunk(
  "user/fetchUserBySearchTerm",
  async (searchTerm) => {
    const { data } = await axios.get(`/api/admin/users/search/${searchTerm}`);
    return data;
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ limit, userId }) => {
    const { data } = await axios.put(`/api/admin/users/${userId}`, { limit });
    return data;
  }
);

export const updateUserStatus = createAsyncThunk(
  "user/updateUserStatus",
  async ({ status, userId }) => {
    const { data } = await axios.put(`/api/admin/users/${userId}`, { status });
    return data;
  }
);

const INIT_STATE = {
  users: [],
  isLoading: true,
  hasError: false,
};
//Slice
/////////////////////////////////////////////////////////////
const userSlice = createSlice({
  name: "user",
  initialState: INIT_STATE,
  reducers: {
    setNew(state, action) {
      return { ...state };
    },
    clearCompany(state, action) {
      return INIT_STATE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(fetchUserBySearchTerm.fulfilled, (state, action) => {
        state.users = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const userIdx = state.users.findIndex(
          (user) => user.id === action.payload.id
        );
        state.users[userIdx] = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        const userIdx = state.users.findIndex(
          (user) => user.id === action.payload.id
        );
        state.users[userIdx] = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state = INIT_STATE;
      });
  },
});

//Actions
/////////////////////////////////////////////////////////////
export const { setNew } = userSlice.actions;

//Reducer
/////////////////////////////////////////////////////////////
export default userSlice.reducer;

//Selectors
/////////////////////////////////////////////////////////////
export const selectUser = (state) => state.user;
