import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchCompanyBySearchTerm,
  fetchUserCompanies,
  fetchUserCompanyBySearchTerm,
  updateCompanyStatus,
} from "../redux/reducers/company";
import { Table, Container, Button, Form } from "react-bootstrap";
import CreateCompanyModal from "./modals/CreateCompanyModal";
import getBaseUrl from "../utils/getBaseUrl";
import ReportModal from "./modals/ReportModal";
import CreateUserCompanyModal from "./modals/CreateUserCompanyModal";
import UserReportModal from "./modals/UserReportModal";
import EditCompanyPasswordModal from "./modals/EditCompanyPasswordModal";

const Dashboard = ({
  companies,
  getCompanies,
  searchCompanies,
  updateStatus,
  surpassedLimit,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getCompanies();
  }, []);

  const handleSearch = ({ target }) => {
    setSearchTerm(target.value);
    if (target.value !== "") {
      searchCompanies(target.value);
    } else {
      getCompanies();
    }
  };
  const handleChange = (companyId) => {
    updateStatus(companyId);
  };
  return (
    <Container>
      <Container className="d-flex justify-content-between align-items-center">
        <h2>Companies</h2>
        <Container className="d-flex justify-content-between align-items-center">
          <Form.Control
            name="search"
            type="text"
            placeholder="search..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <Container
            className="d-flex justify-content-between align-items-center"
            style={{ width: "auto" }}
          >
            <Container>
              <UserReportModal />
            </Container>
            <Container>
              <CreateUserCompanyModal {...{ surpassedLimit }} />
            </Container>
          </Container>
        </Container>
      </Container>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th width="15%">Submissions</th>
            <th width="25%">Name</th>
            <th width="25%">Password</th>
            <th width="15%">Category</th>
            <th width="10%">Active</th>
            <th width="10%">Report</th>
          </tr>
        </thead>
        <tbody>
          {companies.length ? (
            companies.map((company) => (
              <tr key={company.id}>
                <td width="15%">{company.questions.length}</td>
                <td width="25%">{company.name}</td>
                <td width="25%">
                  {company.password}{" "}
                  <EditCompanyPasswordModal
                    companyId={company.id}
                    companyPassword={company.password}
                  />
                </td>
                <td width="15%">{company.category && company.category.name}</td>
                <td width="10%">
                  <Form.Check
                    inline
                    name="status"
                    checked={company.status === "Active"}
                    type="checkbox"
                    onChange={() => {
                      if (company.status === "Active") {
                        handleChange(company.id);
                      } else if (!surpassedLimit) {
                        handleChange(company.id);
                      } else {
                        alert("Limit surpassed");
                      }
                    }}
                  />
                </td>
                <td width="10%">
                  <Button
                    onClick={() =>
                      window.open(
                        `${getBaseUrl()}/api/userdownloads/company/${
                          company.id
                        }`
                      )
                    }
                  >
                    Download
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" align="center">
                No Companies
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

const mapState = (state) => {
  const { companies } = state.company;
  const { auth } = state.auth;
  const activeCompanyCount = companies.filter(
    (company) => company.status === "Active"
  ).length;
  const surpassedLimit = auth.limit <= activeCompanyCount;
  return {
    companies,
    surpassedLimit,
  };
};

const mapDispatch = (dispatch) => {
  return {
    getCompanies() {
      dispatch(fetchUserCompanies());
    },
    updateStatus(companyId) {
      dispatch(updateCompanyStatus(companyId));
    },
    searchCompanies(searchTerm) {
      dispatch(fetchUserCompanyBySearchTerm(searchTerm));
    },
  };
};

export default connect(mapState, mapDispatch)(Dashboard);
