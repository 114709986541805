/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

export const Authenticate = ({ auth }) => {
  if (auth.role === "Admin") {
    return <Redirect to="/admin/dashboard" />;
  } else if (auth.role === "User") {
    return <Redirect to="/dashboard" />;
  }
  return <div>Authenticaion Failed...</div>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Authenticate);
