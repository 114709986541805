import React, { useState } from "react";
import { connect } from "react-redux";
import { updateNotifications } from "../redux/reducers/auth";
import { Container, Button, Form } from "react-bootstrap";

const Account = ({ changeNotifications, currentNotifications }) => {
  const [notifications, setNotifications] = useState(currentNotifications);
  return (
    <Container>
      <h2>Weekly Notifications</h2>
      <Form.Check
        type="switch"
        id="custom-switch"
        label={notifications}
        checked={notifications === "On"}
        onChange={({ target: { checked } }) => {
          setNotifications(checked ? "On" : "Off");
          changeNotifications(checked ? "On" : "Off");
        }}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { auth } = state.auth;
  return {
    currentNotifications: auth.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeNotifications(status) {
      dispatch(updateNotifications(status));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
