import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import { createCompany } from "../../redux/reducers/company";
import { fetchCategories } from "../../redux/reducers/category";
import { updateUser } from "../../redux/reducers/user";

const CreateTransactionModal = ({
  userLimit,
  userId,
  updateLimit,
  userCompanies,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [limit, setLimit] = useState(userLimit);
  const activeCompanyCount = userCompanies.filter(
    (company) => company.status === "Active"
  ).length;
  const handleUpdate = () => {
    if (limit < activeCompanyCount) {
      alert(`User has ${activeCompanyCount} active companies`);
    } else {
      updateLimit({ limit, userId });
      setModalShow(false);
    }
  };
  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Edit
      </Button>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Limit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Limit</Form.Label>
              <Form.Control
                type="number"
                value={limit}
                onChange={({ target: { value } }) => setLimit(value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate}>Update</Button>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapState = (state) => {
  return {};
};
const mapDispatch = (dispatch) => {
  return {
    updateLimit(data) {
      dispatch(updateUser(data));
    },
  };
};

export default connect(mapState, mapDispatch)(CreateTransactionModal);
