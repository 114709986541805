import React from "react";
import { connect } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";

const CompanyForm = ({ handleChange, categories }) => {
  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Company Name</Form.Label>
        <Form.Control
          name="companyName"
          type="text"
          id="name"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control
          name="companyPassword"
          type="text"
          id="password"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Category</Form.Label>
        <Form.Control
          name="companyCategory"
          type="text"
          list="companyCategory"
          id="category"
          onChange={handleChange}
        />
        <datalist id="companyCategory">
          {categories.map((category) => (
            <option key={category.id} value={category.name} />
          ))}
        </datalist>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
