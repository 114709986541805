import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { requestReset, resetRequestPassword } from "../redux/reducers/auth";
import { Redirect } from "react-router-dom";

const RequestForgotPassword = ({
  requestResetPassword,
  requestedPasswordReset,
  resetFlag,
}) => {
  const [email, setEmail] = useState("");
  if (requestedPasswordReset) {
    resetFlag();
    return <Redirect to="/" />;
  }
  return (
    <Form>
      <div className="mb-4">
        <Form.Label htmlFor="email">Email Address</Form.Label>
        <Form.Control
          name="email"
          type="email"
          placeholder="name@address.com"
          autoComplete="off"
          required
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
        />
      </div>
      <Button onClick={() => requestResetPassword(email)}>Send</Button>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const { requestedPasswordReset } = state.auth;
  return {
    requestedPasswordReset,
  };
};

const mapDispatch = (dispatch) => {
  return {
    resetFlag() {
      dispatch(resetRequestPassword());
    },
    requestResetPassword(email) {
      dispatch(requestReset(email));
    },
  };
};

export default connect(mapStateToProps, mapDispatch)(RequestForgotPassword);
