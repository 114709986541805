/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { me } from "../redux/reducers/auth";

import Portal from "../interface/Portal";
import AuthForm from "../views/AuthForm";
import FourOhFour from "../views/FourOhFour";
import Home from "../views/Home";
import Authenticate from "../views/Authenticate";
import AdminDashboard from "../views/AdminDashboard";
import QuestionForm from "../views/QuestionForm";
import Privacy from "../views/Privacy";
import Terms from "../views/Terms";
import OptOut from "../views/OptOut";
import Dashboard from "../views/Dashboard";
import AdminUsersDashboard from "../views/AdminUsersDashboard";
import RegisterInvite from "../views/RegisterInvite";
import Account from "../views/Account";
import RequestForgotPassword from "../views/RequestForgotPassword";
import ForgotPassword from "../views/ForgotPassword";

const Routes = ({ getMe, isLoggedIn, preCheck, auth }) => {
  useEffect(() => {
    getMe();
  }, [isLoggedIn]);

  const renderer = (Component, props) => (
    <Portal {...{ Component, props, preCheck, auth }} />
  );

  return (
    <>
      {isLoggedIn ? (
        <Switch>
          <Route
            exact
            path="/authenticate/*"
            render={(props) => renderer(Authenticate, props)}
          />
          <Route
            exact
            path="/invite/*"
            render={(props) => renderer(Authenticate, props)}
          />
          <Route exact path="/" render={(props) => renderer(Home, props)} />

          <Route
            exact
            path="/account"
            render={(props) => renderer(Account, props)}
          />
          <Route
            exact
            path="/admin/dashboard"
            render={(props) => renderer(AdminUsersDashboard, props)}
          />
          <Route
            exact
            path="/admin/dashboard/companies"
            render={(props) => renderer(AdminDashboard, props)}
          />
          <Route
            exact
            path="/dashboard"
            render={(props) => renderer(Dashboard, props)}
          />
          <Route
            exact
            path="/forms/:companyId"
            render={(props) => renderer(QuestionForm, props)}
          />
          <Route
            exact
            path="/termsandconditions"
            render={(props) => renderer(Terms, props)}
          />
          <Route
            exact
            path="/privacypolicy"
            render={(props) => renderer(Privacy, props)}
          />
          <Route
            exact
            path="/opt-out"
            render={(props) => renderer(OptOut, props)}
          />
          <Route
            exact
            path="/:any"
            render={(props) => renderer(FourOhFour, props)}
          />
        </Switch>
      ) : (
        <Switch>
          <Route
            exact
            path="/authenticate/login"
            render={(props) => renderer(AuthForm, props)}
          />
          <Route
            exact
            path="/invite/:accessToken"
            render={(props) => renderer(RegisterInvite, props)}
          />
          <Route
            exact
            path="/authenticate/forgot-password"
            component={RequestForgotPassword}
          />
          <Route
            exact
            path="/authenticate/forgot-password/:accessToken"
            component={ForgotPassword}
          />
          <Route
            exact
            path="/termsandconditions"
            render={(props) => renderer(Terms, props)}
          />
          <Route
            exact
            path="/privacypolicy"
            render={(props) => renderer(Privacy, props)}
          />
          <Route
            exact
            path="/opt-out"
            render={(props) => renderer(OptOut, props)}
          />
          <Route
            exact
            path="/forms/:companyId"
            render={(props) => renderer(QuestionForm, props)}
          />
          <Route exact path="/" render={(props) => renderer(Home, props)} />
        </Switch>
      )}
    </>
  );
};

const mapState = (state) => {
  const { auth, preCheck, validInvitation } = state.auth;
  const isLoggedIn = !!auth.id;
  return {
    isLoggedIn,
    auth,
    preCheck,
    validInvitation,
  };
};

const mapDispatch = (dispatch) => {
  return {
    getMe() {
      dispatch(me());
    },
  };
};

export default connect(mapState, mapDispatch)(Routes);
