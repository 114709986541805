import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Modal, Form, Container } from "react-bootstrap";
import { createCompany } from "../../redux/reducers/company";
import { fetchCategories } from "../../redux/reducers/category";
import getBaseUrl from "../../utils/getBaseUrl";
import Divider from "../Divider";
const UserReportModal = ({ companies, getCategories, categories, userId }) => {
  useEffect(() => {
    getCategories();
  }, [companies]);

  const [modalShow, setModalShow] = useState(false);
  const [categoryId, setCategoryId] = useState(null);

  const isDisabled = categoryId === null || categoryId === "Select Category";

  const handleSelect = ({ target }) => {
    setCategoryId(target.value);
  };
  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Reports
      </Button>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Reporting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="d-flex justify-content-between align-items-center">
            <h3>All Companies</h3>
            <Button
              onClick={() =>
                window.open(
                  `${getBaseUrl()}/api/userdownloads/${userId}/company`
                )
              }
            >
              Download
            </Button>
          </Container>
          <Divider />
          <Container className="d-flex justify-content-between align-items-center">
            <h3>All Demographics</h3>
            <Button
              onClick={() =>
                window.open(
                  `${getBaseUrl()}/api/userdownloads/${userId}/demographics`
                )
              }
            >
              Download
            </Button>
          </Container>
          <Divider />
          <Container className="d-flex justify-content-between align-items-center">
            <h3>Category</h3>
            <Form.Select
              aria-label="Select Category"
              onChange={handleSelect}
              style={{ width: "50%" }}
            >
              <option>Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
            <Button
              disabled={isDisabled}
              onClick={() =>
                window.open(
                  `${getBaseUrl()}/api/userdownloads/category/${categoryId}`
                )
              }
            >
              Download
            </Button>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapState = (state) => {
  const { companies } = state.company;
  const { categories } = state.category;
  const { auth } = state.auth;
  return {
    companies,
    categories,
    userId: auth.id,
  };
};
const mapDispatch = (dispatch) => {
  return {
    addCompany(formData) {
      dispatch(createCompany(formData));
    },
    getCategories() {
      dispatch(fetchCategories());
    },
  };
};

export default connect(mapState, mapDispatch)(UserReportModal);
