/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import history from "../../utils/history";

export const fetchCompanies = createAsyncThunk(
  "company/fetchCompanies",
  async () => {
    const { data } = await axios.get(`/api/admin/companies`);

    return data;
  }
);

export const fetchUserCompanies = createAsyncThunk(
  "company/fetchUserCompanies",
  async () => {
    const { data } = await axios.get(`/api/users/companies`);

    return data;
  }
);

export const createUserCompany = createAsyncThunk(
  "company/createUserCompany",
  async (formData) => {
    const { data } = await axios.post(`/api/users/companies`, formData);
    return data;
  }
);

export const updateCompanyStatus = createAsyncThunk(
  "company/updateCompanyStatus",
  async (companyId) => {
    const { data } = await axios.put(
      `/api/users/companies/${companyId}/status`
    );
    return data;
  }
);

export const updateCompanyPassword = createAsyncThunk(
  "company/updateCompanyPassword",
  async ({ companyId, password }) => {
    const { data } = await axios.put(
      `/api/users/companies/${companyId}/password`,
      { password }
    );
    return data;
  }
);

export const createCompany = createAsyncThunk(
  "company/createCompany",
  async (formData) => {
    const { data } = await axios.post(`/api/admin/companies`, formData);
    return data;
  }
);

export const fetchCompanyPassword = createAsyncThunk(
  "company/fetchCompanyPassword",
  async (password) => {
    const { data } = await axios.get(`/api/companies/${password}`);
    if (!data.id) alert("Password Incorrect");
    return data;
  }
);

export const fetchCompanyBySearchTerm = createAsyncThunk(
  "company/fetchCompanyBySearchTerm",
  async (searchTerm) => {
    const { data } = await axios.get(
      `/api/admin/companies/search/${searchTerm}`
    );
    return data;
  }
);

export const fetchUserCompanyBySearchTerm = createAsyncThunk(
  "company/fetchUserCompanyBySearchTerm",
  async (searchTerm) => {
    const { data } = await axios.get(`/api/companies/search/${searchTerm}`);
    return data;
  }
);

const INIT_STATE = {
  companies: [],
  company: {},
  passwordCorrect: false,
  isLoading: true,
  hasError: false,
};
//Slice
/////////////////////////////////////////////////////////////
const companySlice = createSlice({
  name: "company",
  initialState: INIT_STATE,
  reducers: {
    setNew(state, action) {
      return { ...state };
    },
    clearCompany(state, action) {
      return INIT_STATE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(fetchUserCompanies.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(createUserCompany.fulfilled, (state, action) => {
        state.companies = [...state.companies, action.payload];
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.companies = [...state.companies, action.payload];
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(fetchCompanyPassword.fulfilled, (state, action) => {
        state.company = action.payload;
        state.passwordCorrect = true;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(fetchCompanyBySearchTerm.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(fetchUserCompanyBySearchTerm.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state = INIT_STATE;
      })
      .addCase(updateCompanyStatus.fulfilled, (state, action) => {
        const companyIdx = state.companies.findIndex(
          (company) => company.id === action.payload.id
        );
        state.companies[companyIdx] = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(updateCompanyPassword.fulfilled, (state, action) => {
        const companyIdx = state.companies.findIndex(
          (company) => company.id === action.payload.id
        );
        state.companies[companyIdx] = action.payload;
        state.isLoading = false;
        state.hasError = false;
      });
  },
});

//Actions
/////////////////////////////////////////////////////////////
export const { setNew, clearCompany } = companySlice.actions;

//Reducer
/////////////////////////////////////////////////////////////
export default companySlice.reducer;

//Selectors
/////////////////////////////////////////////////////////////
export const selectCompany = (state) => state.company;
