import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Divider from "./Divider";
const Privacy = () => {
  return (
    <Container style={{ height: "100%" }}>
      <Container className="d-flex flex-column align-items-center justify-content-center w-100 pt-3">
        <h1>Privacy Policy</h1>
        <Divider />
      </Container>
      <Container className="d-flex flex-column align-items-start justify-content-center w-100 pt-1">
        <p>
          Protecting your private information is our priority. This Statement of
          Privacy applies to Kollektor, and Kollektor, LLC and governs data
          collection and usage. For the purposes of this Privacy Policy, unless
          otherwise noted, all references to Kollektor, LLC include{" "}
          <span>
            <a href="https://www.kollektor.app">https://www.kollektor.app</a>
          </span>{" "}
          and Kollektor. The Kollektor application is a data collection
          application. By using the Kollektor application, you consent to the
          data practices described in this statement.
        </p>
      </Container>
      <Container className="d-flex flex-column align-items-start justify-content-center w-100 pt-1">
        <h3>Collection of your Personal Information</h3>
        <p>
          In order to better provide you with products and services offered,
          Kollektor may collect personally identifiable information, such as
          your:
        </p>
        {"\n"}
        {"\n"}
        <ul>
          {["E-mail Address", "zip code"].map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        {"\n"}
        {"\n"}
        <p>
          Kollektor may also collect anonymous demographic information, which is
          not unique to you, such as your:
        </p>
        {"\n"}
        {"\n"}
        <ul>
          {["Age", "Gender"].map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        {"\n"}
        {"\n"}
        <p>
          We do not collect any personal information about you unless you
          voluntarily provide it to us. However, you may be required to provide
          certain personal information to us when you elect to use certain
          products or services. These may include: (a) registering for an
          account; (b) entering a sweepstakes or contest sponsored by us or one
          of our partners; (c) signing up for special offers from selected third
          parties; (d) sending us an email message; (e) submitting your credit
          card or other payment information when ordering and purchasing
          products and services. To wit, we will use your information for, but
          not limited to, communicating with you in relation to services and/or
          products you have requested from us. We also may gather additional
          personal or non-personal information in the future.
        </p>
      </Container>
      <Container className="d-flex flex-column align-items-start justify-content-center w-100 pt-1">
        <h3>Use of your Personal Information</h3>
        <p>
          Kollektor collects and uses your personal information to operate and
          deliver the services you have requested.
        </p>
        {"\n"}
        <p>
          Kollektor may also use your personally identifiable information to
          inform you of other products or services available from Kollektor and
          its affiliates.
        </p>
      </Container>
      <Container className="d-flex flex-column align-items-start justify-content-center w-100 pt-1">
        <h3>Sharing Information with Third Parties</h3>
        <p>
          Kollektor may sell, rent, or lease customer information to third
          parties for the following reasons:
        </p>
        {"\n"}
        <p>
          Only the users facebook interests, gender, age, and zip code would be
          shared. We will not share your email address. The information that is
          shared does not give the purchasing party the ability to target you
          directly with any sort of advertisements or marketing.
        </p>
        {"\n"}
        <p>
          Kollektor may share data with trusted partners to help perform
          statistical analysis, send you email or postal mail, provide customer
          support, or arrange for deliveries. All such third parties are
          prohibited from using your personal information except to provide
          these services to Kollektor, and they are required to maintain the
          confidentiality of your information.
        </p>
        {"\n"}
        <p>
          Kollektor may disclose your personal information, without notice, if
          required to do so by law or in the good faith belief that such action
          is necessary to: (a) conform to the edicts of the law or comply with
          legal process served on Kollektor or the site; (b) protect and defend
          the rights or property of Kollektor; and/or (c) act under exigent
          circumstances to protect the personal safety of users of Kollektor, or
          the public.
        </p>
      </Container>
      <Container className="d-flex flex-column align-items-start justify-content-center w-100 pt-1">
        <h3>Opt-Out of Sale of Personal Information to Third Parties</h3>
        <p>
          In connection with any personal information we may sell to a third
          party for a business purpose, you have the right to know:
        </p>
        {"\n"}
        <ul>
          <li>
            The categories of personal information about you that we sold and
            the categories of third parties to whom the personal information was
            sold.
          </li>
        </ul>
        {"\n"}
        <p>
          You have the right under the California Consumer Privacy Act of 2018
          (CCPA) and certain other privacy and data protection laws, as
          applicable, to opt-out of the sale of your personal information. If
          you exercise your right to opt-out of the sale of your personal
          information, we will refrain from selling your personal information,
          unless you subsequently provide express authorization for the sale of
          your personal information. To opt-out of the sale of your personal
          information, visit this Web page: <Link to="/opt-out">Opt-Out</Link>.
        </p>
      </Container>
      <Container className="d-flex flex-column align-items-start justify-content-center w-100 pt-1">
        <h3>Right to Deletion</h3>
        <p>
          Subject to certain exceptions set out below, on receipt of a
          verifiable request from you, we will
        </p>
        {"\n"}
        <ul>
          {[
            "Delete your personal information from our records; and",
            "Direct any service providers to delete your personal information from their records.",
          ].map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        {"\n"}
        {"\n"}
        <p>
          Please note that we may not be able to comply with requests to delete
          your personal information if it is necessary to:
        </p>
        {"\n"}
        <ul>
          {[
            "Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;",
            "Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;",
            "Debug to identify and repair errors that impair existing intended functionality;",
            "Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;",
            "Comply with the California Electronic Communications Privacy Act;",
            "Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;",
            "Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;",
            "Comply with an existing legal obligation; or",
            "Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.",
          ].map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </Container>
      <Container className="d-flex flex-column align-items-start justify-content-center w-100 pt-1">
        <h3>Children Under Thirteen</h3>
        <p>
          Kollektor does not knowingly collect personally identifiable
          information from children under the age of thirteen. If you are under
          the age of thirteen, you must ask your parent or guardian for
          permission to use this application.
        </p>
      </Container>
      <Container className="d-flex flex-column align-items-start justify-content-center w-100 pt-1">
        <h3>External Data Storage Sites</h3>
        <p>
          We may store your data on servers provided by third party hosting
          vendors with whom we have contracted.
        </p>
      </Container>
      <Container className="d-flex flex-column align-items-start justify-content-center w-100 pt-1">
        <h3>Changes to this Statement</h3>
        <p>
          Kollektor reserves the right to change this Privacy Policy from time
          to time. We will notify you about significant changes in the way we
          treat personal information by sending a notice to the primary email
          address specified in your account, by placing a prominent notice on
          our application, and/or by updating any privacy information. Your
          continued use of the application and/or Services available after such
          modifications will constitute your: (a) acknowledgment of the modified
          Privacy Policy; and (b) agreement to abide and be bound by that
          Policy.
        </p>
      </Container>
      <Container className="d-flex flex-column align-items-start justify-content-center w-100 pt-1">
        <h3>Contact Information</h3>
        <p>
          Kollektor welcomes your questions or comments regarding this Statement
          of Privacy. If you believe that Kollektor has not adhered to this
          Statement, please contact Kollektor at:
        </p>
      </Container>
      <Container className="d-flex flex-column align-items-start justify-content-center w-100 pt-2">
        <p style={{ whiteSpace: "pre-wrap" }}>
          Kollektor, LLC{"\n"}1550 Niagara Rd{"\n"}Montrose, Colorado 81401
        </p>
      </Container>
      <Container className="d-flex flex-column align-items-start justify-content-center w-100 pt-2">
        <p style={{ whiteSpace: "pre-wrap" }}>
          Email Address:{"\n"}
          <span>
            <a href="mailto:support@kollektor.app">support@kollektor.app</a>
          </span>
          {"\n"}
          {"\n"}Telephone number:{"\n"}
          <span>
            <a href="tel:9707650594">970-765-0594</a>
          </span>
        </p>
        {"\n"}
        <p>Effective as of January 01, 2022</p>
      </Container>
    </Container>
  );
};

export default Privacy;
