import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchCompanies,
  fetchCompanyBySearchTerm,
} from "../redux/reducers/company";
import { Table, Container, Button, Form } from "react-bootstrap";
import CreateCompanyModal from "./modals/CreateCompanyModal";
import getBaseUrl from "../utils/getBaseUrl";
import ReportModal from "./modals/ReportModal";
import EditCompanyPasswordModal from "./modals/EditCompanyPasswordModal";

const AdminDashboard = ({ companies, getCompanies, searchCompanies }) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getCompanies();
  }, []);

  const handleSearch = ({ target }) => {
    setSearchTerm(target.value);
    if (target.value !== "") {
      searchCompanies(target.value);
    } else {
      getCompanies();
    }
  };

  return (
    <Container>
      <Container className="d-flex justify-content-between align-items-center">
        <h2>Companies</h2>
        <Container className="d-flex justify-content-between align-items-center">
          <Form.Control
            name="search"
            type="text"
            placeholder="search..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <Container
            className="d-flex justify-content-between align-items-center"
            style={{ width: "auto" }}
          >
            <Container>
              <ReportModal />
            </Container>
            <Container>
              <CreateCompanyModal />
            </Container>
          </Container>
        </Container>
      </Container>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th width="15%">Submissions</th>
            <th width="15%">Owner</th>
            <th width="20%">Name</th>
            <th width="25%">Password</th>
            <th width="15%">Category</th>
            <th width="10%">Report</th>
          </tr>
        </thead>
        <tbody>
          {companies.length ? (
            companies.map((company) => (
              <tr key={company.id}>
                <td width="15%">{company.questions.length}</td>
                <td width="15%">
                  {company.user ? company.user.email : "No User"}
                </td>
                <td width="20%">{company.name}</td>
                <td width="25%">
                  {company.password}{" "}
                  <EditCompanyPasswordModal
                    companyId={company.id}
                    companyPassword={company.password}
                  />
                </td>
                <td width="15%">{company.category && company.category.name}</td>
                <td width="10%">
                  <Button
                    onClick={() =>
                      window.open(
                        `${getBaseUrl()}/api/downloads/company/${company.id}`
                      )
                    }
                  >
                    Download
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colspan="6" align="center">
                No Companies
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

const mapState = (state) => {
  const { companies } = state.company;
  return {
    companies,
  };
};

const mapDispatch = (dispatch) => {
  return {
    getCompanies() {
      dispatch(fetchCompanies());
    },
    searchCompanies(searchTerm) {
      dispatch(fetchCompanyBySearchTerm(searchTerm));
    },
  };
};

export default connect(mapState, mapDispatch)(AdminDashboard);
