import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Form, Button } from "react-bootstrap";
import { fetchCompanyPassword } from "../redux/reducers/company";
import { Redirect } from "react-router";

const Landing = ({ getCompanyByPassword, company }) => {
  const [pass, setPass] = useState("");

  const handleChange = ({ target }) => {
    setPass(target.value);
  };
  const handleSubmit = ({ target }) => {
    getCompanyByPassword(pass);
  };
  if (company.id) {
    return <Redirect to={`/forms/${company.id}`} />;
  }
  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100%" }}
    >
      <Container
        className="d-flex"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#0d6efd",
          boxShadow: "0px 0px 15px #878787",
        }}
      ></Container>
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%", height: "100%" }}
      >
        {/* <Form>
          <Form.Label>Company Password</Form.Label>
          <Form.Group className="d-flex">
            <Form.Control
              type="text"
              placeholder="password"
              value={pass}
              onChange={handleChange}
            ></Form.Control>
            <Button onClick={handleSubmit}>Submit</Button>
          </Form.Group>
        </Form> */}
      </Container>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { company } = state.company;
  return {
    company,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyByPassword: (password) => {
      dispatch(fetchCompanyPassword(password));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
