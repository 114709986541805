/* eslint-disable no-unused-vars */
import React from "react";
import Routes from "../routes/Routes";
import Home from "../views/Home";
import { Container } from "react-bootstrap";
import NavBar from "./Nav";

const Main = () => {
  return (
    <Container>
      <NavBar />
      <Container style={{ marginTop: 56 }}>
        <Routes />
      </Container>
    </Container>
  );
};

export default Main;
