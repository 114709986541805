import { createStore, applyMiddleware } from "redux";
// import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./index";

// const middleware = composeWithDevTools(
//   applyMiddleware(thunkMiddleware, createLogger({ collapsed: true }))
// );
// const store = createStore(rootReducer, middleware);
const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export default store;
