/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import history from "../../utils/history";

export const fetchCategories = createAsyncThunk(
  "category/fetchCategories",
  async () => {
    const { data } = await axios.get(`/api/categories`);
    return data;
  }
);

const INIT_STATE = {
  categories: [],
  isLoading: true,
  hasError: false,
};
//Slice
/////////////////////////////////////////////////////////////
const categorySlice = createSlice({
  name: "category",
  initialState: INIT_STATE,
  reducers: {
    setNew(state, action) {
      return { ...state };
    },
    clearCompany(state, action) {
      return INIT_STATE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state = INIT_STATE;
      });
  },
});

//Actions
/////////////////////////////////////////////////////////////
export const { setNew } = categorySlice.actions;

//Reducer
/////////////////////////////////////////////////////////////
export default categorySlice.reducer;

//Selectors
/////////////////////////////////////////////////////////////
export const selectCategory = (state) => state.category;
