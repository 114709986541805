import React from "react";
import Questions from "./Questions";
import { Container, Form, NavLink, Button } from "react-bootstrap";
import Landing from "./Landing";

const Home = () => {
  return (
    <Container style={{ height: "100%" }}>
      <Landing />
    </Container>
  );
};

export default Home;
