import React from "react";

import { Container } from "react-bootstrap";
import Divider from "./Divider";
const OptOut = () => {
  return (
    <Container style={{ height: "100%" }}>
      <Container className="d-flex flex-column align-items-center justify-content-center w-100 pt-3">
        <h1>Opt-Out of Sale of Personal Information to Third Parties</h1>
        <Divider />
      </Container>
      <Container className="d-flex flex-column align-items-start justify-content-center w-100 pt-1">
        <h3>Contact Us</h3>
        <p style={{ whiteSpace: "pre-wrap" }}>
          Email Address:{"\n"}
          <span>
            <a href="mailto:support@kollektor.app">support@kollektor.app</a>
          </span>
          {"\n"}
          {"\n"}Telephone number:{"\n"}
          <span>
            <a href="tel:9707650594">970-765-0594</a>
          </span>
        </p>
      </Container>
    </Container>
  );
};

export default OptOut;
