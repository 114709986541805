import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchCompanies,
  fetchCompanyBySearchTerm,
} from "../redux/reducers/company";
import { Table, Container, Button, Form } from "react-bootstrap";
import CreateCompanyModal from "./modals/CreateCompanyModal";
import getBaseUrl from "../utils/getBaseUrl";
import ReportModal from "./modals/ReportModal";
import { fetchUserBySearchTerm, fetchUsers } from "../redux/reducers/user";
import EditUserCompanyModal from "./modals/EditUserCompanyModal";
import { Link } from "react-router-dom";
import AdminUserReportModal from "./modals/AdminUserReportModal";
import { resendInvitation } from "../redux/reducers/auth";
import EditUserStatusModal from "./modals/EditUserStatusModal";

const AdminUsersDashboard = ({
  companies,
  getUsers,
  users,
  searchUsers,
  refreshInvite,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getUsers();
  }, []);

  const handleSearch = ({ target }) => {
    setSearchTerm(target.value);
    if (target.value !== "") {
      searchUsers(target.value);
    } else {
      getUsers();
    }
  };

  return (
    <Container>
      <Container className="d-flex justify-content-between align-items-center">
        <h2>Users</h2>
        <Container className="d-flex justify-content-between align-items-center">
          <Form.Control
            name="search"
            type="text"
            placeholder="search..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </Container>
        <Container>
          <Link to="/admin/dashboard/companies">View all companies</Link>
        </Container>
      </Container>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th width="20%">Name</th>
            <th width="20%">Email</th>
            <th width="10%">Limit</th>
            <th width="20%">Active Companies</th>
            <th width="10%">Limit</th>
            <th width="10%">Status</th>
            <th width="10%">Reports</th>
          </tr>
        </thead>
        <tbody>
          {users.length ? (
            users.map((user) => (
              <tr key={user.id}>
                <td width="20%">{user.companyName}</td>
                <td width="20%">{user.email}</td>
                <td width="10%">{user.limit}</td>
                <td width="20%">{user.companies.length}</td>
                <td width="10%">
                  <EditUserCompanyModal
                    userLimit={user.limit}
                    userId={user.id}
                    userCompanies={user.companies}
                  />
                </td>
                <td width="10%">
                  {user.status === "Pending" ? (
                    <Button onClick={() => refreshInvite(user.id)}>
                      Resend
                    </Button>
                  ) : (
                    <EditUserStatusModal
                      userId={user.id}
                      userStatus={user.status}
                    />
                  )}
                </td>
                <td width="10%">
                  <AdminUserReportModal
                    userName={user.fullName}
                    userId={user.id}
                    companies={user.companies}
                    categories={user.categories}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colspan="7" align="center">
                No Users
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

const mapState = (state) => {
  const { users } = state.user;

  return {
    users,
  };
};

const mapDispatch = (dispatch) => {
  return {
    refreshInvite(userId) {
      dispatch(resendInvitation(userId));
    },
    getUsers() {
      dispatch(fetchUsers());
    },
    searchUsers(searchTerm) {
      dispatch(fetchUserBySearchTerm(searchTerm));
    },
  };
};

export default connect(mapState, mapDispatch)(AdminUsersDashboard);
