/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import history from "../../utils/history";

export const executeScraper = createAsyncThunk(
  "scraper/executeScraper",
  async () => {
    const { data } = await axios.get(`/api/scraper/run`);

    return data;
  }
);

const INIT_STATE = {
  interests: [],
  isLoading: true,
  hasError: false,
};
//Slice
/////////////////////////////////////////////////////////////
const scraperSlice = createSlice({
  name: "scraper",
  initialState: INIT_STATE,
  reducers: {
    setNew(state, action) {
      return { ...state };
    },
    clearCompany(state, action) {
      return INIT_STATE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(executeScraper.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(executeScraper.fulfilled, (state, action) => {
        state.interests = action.payload;
        state.isLoading = false;
        state.hasError = false;
      });
  },
});

//Actions
/////////////////////////////////////////////////////////////
export const { setNew } = scraperSlice.actions;

//Reducer
/////////////////////////////////////////////////////////////
export default scraperSlice.reducer;

//Selectors
/////////////////////////////////////////////////////////////
export const selectCompany = (state) => state.scraper;
